.news{
  display: block;
  position: relative;
  float: left;
  width: 100%;
  background-color: #ffffff;
  &__wrapper{
    @include display-flex(flex);
    @include flex-wrap(wrap);
    @include flex-direction(row);
    @include justify-content(flex-start);
    position: relative;
    float: left;
    width: 100%;
    &--element{
      display: block;
      position: relative;
      float: left;
      width: 263px;
     /* height: 423px;*/
      margin-bottom: 27px;
      margin-right: 19px;
      &:last-of-type{
        margin-right: 0;
      }
    }
    &--date{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-bottom: 9px;
      padding-left: 6px;
      @include box-sizing(border-box);
      color: #878787;
      font-size: 15px;
      font-weight: 400;
    }
    &--border{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      border: 1px solid #e8e8e8;
      > p{
        display: block;
        display: -webkit-box;
        position: relative;
        float: left;
        width: 100%;
        height: calc(15px * 1.2 * 4);
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;
        @include box-sizing(border-box);
        color: rgba(0,0,0,0.59);
        font-size: 15px;
        font-weight: 400;
        line-height: 1.2;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        > span{
          display: block;
          display: -webkit-box;
          position: relative;
          float: left;
          width: 100%;
          height: calc(15px * 1.2 * 4);
          margin: 0;
          padding-left: 20px;
          padding-right: 20px;
          @include box-sizing(border-box);
          color: rgba(0,0,0,0.59);
          font-size: 15px;
          font-weight: 400;
          line-height: 1.2;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          > a{
            display: block;
            position: relative;
            float: left;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            @include box-sizing(border-box);
            margin-top: 5px;
            margin-bottom: 19px;
            color: #003399;
            font-size: 15px;
            font-weight: 400;
            text-decoration: underline;
            &:hover{
              color: #003399;
              text-decoration: none;
            }
          }
        }
      }
      > a{
        display: block;
        position: relative;
        float: left;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        @include box-sizing(border-box);
        margin-top: 5px;
        margin-bottom: 19px;
        color: #003399;
        font-size: 15px;
        font-weight: 400;
        text-decoration: underline;
        &:hover{
          color: #003399;
          text-decoration: none;
        }
      }
    }
    &--photo{
      display: block;
      position: relative;
      width: 100%;
      height: 214px;
      float: left;
      overflow: hidden;
      img{
        size: 100%;
        object-fit: cover;
      }
    }
    &--title{
      display: block;
      display: -webkit-box;
      position: relative;
      float: left;
      width: 100%;
      height: calc(16px* 1.2 * 3);
      margin-top: 24px;
      margin-bottom: 17px;
      padding-left: 20px;
      padding-right: 20px;
      @include box-sizing(border-box);
      text-align: center;
      color: #003399;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__title{
    display: block;
    position: relative;
    float: left;
    color: #002b95;
    font-size: 23px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 17px;
  }
  &__box{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    border: 1px solid #e9e9e9;
    padding: 21px;
    @include box-sizing(border-box);
    margin-bottom: 42px;
    > p{
      display: block;
      position: relative;
      float: none;
      color: #131313;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
    }
    &--photo{
      display: block;
      position: relative;
      float: right;
      width: 450px;
      height: 260px;
      margin-bottom: 28px;
      overflow: hidden;
      @include breakpoint(max-width 700px){
        width: 300px;
        height: 150px;
        margin-right: calc(50% - (300px/2));
      }
      img{
        size: 100%;
        object-fit: cover;
      }
    }
  }
}
.poster{
  display: block;
  position: relative;
  float: left;
  width: 100%;
  &__top-title{
    display: block;
    position: relative;
    float: left;
    width: 500px;
    margin-left: calc(50% - (500px/2));
    margin-top: 178px;
    margin-bottom: 13px;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    @include breakpoint(max-width 700px){
      width: 100%;
      margin-left: 0;
    }
    &:before{
      display: block;
      position: absolute;
      content: '';
      width: 73px;
      height: 21px;
      top: 8px;
      left: 100px;
      background-image: url('../img/icons/Stars_left.png');
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    &:after{
      display: block;
      position: absolute;
      content: '';
      width: 73px;
      height: 21px;
      top: 8px;
      right: 100px;
      background-image: url('../img/icons/Stars_right.png');
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }
  &__separator{
    display: block;
    position: relative;
    float: left;
    width: 500px;
    height: 1px;
    background-color: #40aee3;
    margin-bottom: 36px;
    margin-left: calc(50% - (500px/2));
    @include breakpoint(max-width 700px){
      width: 80%;
      margin-left: 10%;
    }
  }
  &__item{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(154, 154, 154, 0.53);
    background-color: #ffffff;
    padding: 16px 19px;
    @include box-sizing(border-box);
    margin-bottom: 31px;
    &:after{
      display: block;
      position: absolute;
      content: '';
      width: 64px;
      height: 19px;
      margin-left: calc(50% - 55px);
      bottom: -25px;
      background-image: url('../img/icons/StarsBG_1.png');
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    &--photo{
      display: block;
      position: relative;
      float: left;     
      width: 197px;
      height: 260px;
      overflow: hidden;
      @include breakpoint(max-width 440px){
        width: 194px;
        margin-left: calc(50% - (194px/2));
        margin-bottom: 8px;
      }
      img{
        size: 100%;
      }
    }
    &--title{
      display: block;
      position: relative;
      float: left;
      width: calc(100% - 197px);
      padding-left: 16px;
      @include box-sizing(border-box);
      margin-top: 0;
      margin-bottom: 0;
      color: #0669b2;
      font-size: 27px;
      font-weight: 700;
      text-transform: uppercase;
      @include breakpoint(max-width 440px){
        width: 100%;
        margin-bottom: 8px;
      }
    }
    &--date{
      display: inline-block;
      position: relative;
      margin-left: 16px;
      margin-right: 21px;
      color: #cb4741;
      font-size: 26px;
      font-weight: 700;
      @include breakpoint(max-width 700px){
        width: calc(100% - 197px);
        padding-left: 16px;
        @include box-sizing(border-box);
        margin-bottom: 8px;
        margin-left: 0;
        margin-right: 0;
      }
      @include breakpoint(max-width 440px){
        width: 100%;
        margin-bottom: 8px;
      }
    }
    &--start{
      display: inline-block;
      position: relative;
      margin-right: 24px;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      @include breakpoint(max-width 700px){
        width: calc(100% - 197px);
        padding-left: 16px;
        @include box-sizing(border-box);
        margin-bottom: 8px;
        margin-left: 0;
        margin-right: 0;
      }
      @include breakpoint(max-width 440px){
        width: 100%;
        margin-bottom: 8px;
      }
    }
    &--place{
      display: inline-block;
      position: relative;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      @include breakpoint(max-width 700px){
        width: calc(100% - 197px);
        padding-left: 16px;
        @include box-sizing(border-box);
        margin-bottom: 8px;
        margin-left: 0;
        margin-right: 0;
      }
      @include breakpoint(max-width 440px){
        width: 100%;
        margin-bottom: 8px;
      }
      > span{
        color: #cb4741;
        font-weight: 700;
      }
    }
    &--separator{
      display: inline-block;
      position: relative;
      float: left;
      width: calc(100% - (197px + 16px));
      height: 1px;
      background-color: #003399;
      margin-left: 16px;
      margin-top: 8px;
      @include breakpoint(max-width 440px){
        width: 80%;
        margin-left: 10%;
        margin-bottom: 8px;
      }
    }
    > p{
      display: inline-block;
      display: -webkit-box;
      position: relative;
      width: calc(100% - 197px);
      height: calc(16px * 1.2 * 3);
      margin-top: 27px;
      margin-bottom: 0;
      padding-left: 16px;
      @include box-sizing(border-box);
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @include breakpoint(max-width 440px){
        width: 100%;
        margin-bottom: 8px;
      }
      &:last-of-type{
        @include breakpoint(max-width 700px){
          margin-bottom: 47px;
        }
        @include breakpoint(max-width 440px){
          margin-bottom: 47px;
        }
      }
    }
    > p.fullHeights{
      height: auto;
      overflow: inherit;
      -webkit-line-clamp: inherit;
      margin-bottom: 40px;
    }
    > .buy{
      display: block;
      position: absolute;
      bottom: 17px;
      left: 232px;
      width: 148px;
      height: 29px;
      cursor: pointer;
      background-color: #cb4741;
      border: none;
      @include breakpoint(max-width 440px){
        left: calc(50% - (148px/2));
      }
      > span:first-of-type{
        display: inline-block;
        float: left;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.5;
        text-transform: uppercase;
      }
      > span:last-of-type{
        display: inline-block;
        width: 23px;
        height: 23px;
        float: right;
        background-image: url('../img/icons/icon-tickets.png');
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
      }
    }
  }
  &__item:last-of-type:after{
    display: none;
  }
}
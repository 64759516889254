@mixin transition($t) {
	-webkit-transition: all $t linear;
	   -moz-transition: all $t linear;
	    -ms-transition: all $t linear;
	     -o-transition: all $t linear;
	        transition: all $t linear;
}

@mixin overflow-img() {
	overflow: hidden;
	img {
		min-width: 100%;
		height: auto;
		position: relative;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%,-50%);
		   -moz-transform: translate(-50%,-50%);
		    -ms-transform: translate(-50%,-50%);
		     -o-transform: translate(-50%,-50%);
		        transform: translate(-50%,-50%);
	}
}

@mixin border-box{
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
}

@mixin vertical-align {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

// Breakpoints for each query
$smartphone: 480px;
$tabletPortrait: 767px;
$tabletLandscape: 1024px;
$desktop: 1174px;
$largeScreen: 1400px;

@mixin respondTo($media) {
	@if $media == smartphone {
	  @media (min-width: $smartphone) {
			@content;
		}
	}
	@else if $media == tablet {
	  @media (min-width: $tabletPortrait) and (max-width: $tabletLandscape) {
			@content;
		}
	}
	@else if $media == smallScreen {
	  @media (min-width: $desktop) {
			@content;
		}
	}
	@else if $media == desktop {
	  @media (min-width: $desktop) {
			@content;
		}
	}
  @else if $media == largeScreen {
    @media (min-width: $largeScreen) {
			@content;
		}
  }
}

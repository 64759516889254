.footer {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  background-image: url('../img/content/footer_bg.png');
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  &__logo {
    display: block;
    position: relative;
    float: left;
    width: 171px;
    height: 143px;
    margin-top: 47px;
    margin-bottom: 113px;
    overflow: hidden;
    @include breakpoint(max-width 980px){
      width: 150px;
      height: 100px;
      margin-bottom: 0;
    }
    @include breakpoint(max-width 600px){
      width: 150px;
      height: 100px;
      margin-left: calc(50% - (150px/2));
      margin-bottom: 10px;
      margin-top: 10px;
    }
    img {
      size: 100%;
      object-fit: contain;
    }
  }
  &__nav {
    display: block;
    position: relative;
    float: left;
    width: 325px;
    margin-left: 50px;
    @include column-count(2);
    @include column-width(50%);
    margin-top: 48px;
    padding: 0;
    list-style: none;
    @include breakpoint(max-width 980px){
      width: calc(100% - 150px);
      margin-left: 0;
      @include display-flex(flex);
      @include flex-direction(row);
      @include justify-content(space-between);
    }
    @include breakpoint(max-width 600px){
      width: 100%;
      @include flex-direction(column);
      margin-top: 10px;
      margin-bottom: 10px;
    }
    li {
      display: block;
      position: relative;
      width: 100%;
      a {
        display: block;
        position: relative;
        float: left;
        width: 100%;
        text-shadow: 0.5px 0.9px 1px rgba(0, 0, 0, 0.23);
        color: #fffefe;
        font-size: 18px;
        font-weight: 400;
        line-height: 40px;
        @include breakpoint(max-width 980px){
          text-align: center;
          font-size: 15px;
        }
        &:hover, &:active, &:focus {
          color: #ffffff;
          text-decoration: underline;
        }
      }
    }
  }
  &__contacts {
    display: block;
    position: relative;
    float: left;
    width: calc(100% - (171px + 325px + 50px));
    @include breakpoint(max-width 980px){
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
    > h3 {
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 36px;
      margin-bottom: 24px;
      text-shadow: 0.5px 0.9px 1px rgba(0, 0, 0, 0.43);
      color: white;
      font-size: 21px;
      font-weight: 700;
      @include breakpoint(max-width 600px){
       text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  &__descr {
    display: block;
    position: relative;
    float: left;
    width: 60%;
    margin-bottom: 54px;
    padding-left: 35px;
    @include box-sizing(border-box);
    @include breakpoint(max-width 600px){
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &__address {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 15px;
    > p {
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      text-shadow: 0.5px 0.9px 1px rgba(0, 0, 0, 0.23);
      color: white;
      font-size: 15px;
      font-weight: 400;
      line-height: 25px;
      &:first-of-type {
        color: #084388;
      }
    }
    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 28px;
      height: 25px;
      top: 0;
      left: -35px;
      background-image: url('../img/icons/icon_Home.png');
      background-repeat: no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
    }
  }
  &__phone {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    > p {
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      text-shadow: 0.5px 0.9px 1px rgba(0, 0, 0, 0.23);
      color: white;
      font-size: 15px;
      font-weight: 400;
      line-height: 25px;
      &:first-of-type {
        color: #084388;
      }
    }
    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 28px;
      height: 25px;
      top: 0;
      left: -35px;
      background-image: url('../img/icons/phone_icon.png');
      background-repeat: no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
    }
  }
  &__links {
    display: block;
    position: relative;
    float: left;
    width: 40%;
    @include breakpoint(max-width 600px){
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &--socials {
      @include display-flex(flex);
      @include flex-wrap(wrap);
      @include flex-direction(row);
      @include justify-content(flex-start);
      position: relative;
      float: left;
      width: 100%;
      > a{
        display: block;
        position: relative;
        float: left;
        size: 42px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #2665af;
        margin-top: 0;
        margin-left: 3px;
        margin-right: 3px;
        @include breakpoint(max-width 1200px) {
          size: 30px;
        }
      }
    }
    &--email{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 54px;
      padding-left: 60px;
      @include box-sizing(border-box);
      > span{
        display: block;
        position: relative;
        float: left;
        width: 100%;
        &:first-of-type{
          text-shadow: 0.5px 0.9px 1px rgba(0, 0, 0, 0.23);
          color: #084388;
          font-size: 15px;
          font-weight: 400;
        }
        &:last-of-type{
          text-shadow: 0.5px 0.9px 1px rgba(0, 0, 0, 0.23);
          color: white;
          font-size: 15px;
          font-weight: 400;
        }
      }
      &:before{
        display: block;
        position: absolute;
        content: '';
        width: 23px;
        height: 28px;
        top: -4px;
        left: 26px;
        background-image: url('../img/icons/Address Book.png');
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
      }
    }
  }
}
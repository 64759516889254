.vk{
  background-image: url('../img/icons/icon_VK.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}
.fb{
  background-image: url('../img/icons/icon_f.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}
.lin{
  background-image: url('../img/icons/icon_in.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}
.yt{
  background-image: url('../img/icons/icon_tube.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}
.breadcrumbs{
  display: block;
  position: relative;
  float: left;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  @include breakpoint(max-width 1366px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  &__link{
    display: block;
    position: relative;
    float: left;
    margin-right: 17px;
    font-size: 13px;
    font-weight: 400;
    color: #0f0f0f;
    &:hover{
      color: #003399;
      text-decoration: underline;
    }
    &:first-of-type{
      color: #003399;
      text-decoration: underline;
    }
    &:after{
      display: block;
      position: absolute;
      content: '/';
      font-size: 13px;
      font-weight: 400;
      color: #0f0f0f;
      right: -11px;
      top: 0;
    }
  }
  &__link:last-of-type:after{
    display: none;
  }
}
.pagination{
  @include display-flex(flex);
  @include flex-wrap(wrap);
  @include flex-direction(row);
  @include justify-content(center);
  position: relative;
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 36px;
  padding: 0;
  list-style: none;
  > li{
    display: inline-block;
    position: relative;
    width: 21px;
    height: 28px;
    a{
      display: inline-block;
      position: relative;
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-weight: 700;
      color: #2d2d2d;
      text-align: center;
      background-color: transparent;
      line-height: 26px;
      &:hover{
        color: #fff;
        background-color: #ea5a4b;
      }
    }
  }
  > li.active{
    a{
      color: #fff;
      background-color: #ea5a4b;
    }
  }
}
.more{
  display: block;
  position: relative;
  float: left;
  color: #cb4741;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  padding-left: 19px;
  @include box-sizing(border-box);
  margin-bottom: 0;
  &:hover {
    color: #cb4741;
    text-decoration: none;
  }
}
.contacts{
  display: block;
  position: relative;
  float: left;
  width: 100%;
  background-color: #f5f4f5;
  padding-top: 138px;
  @include box-sizing(border-box);
  @include breakpoint(max-width 700px){
    padding-top: 0;
  }
  &__box{
    @include display-flex(flex);
    @include flex-wrap(wrap);
    @include flex-direction(row);
    position: relative;
    float: left;
    width: 100%;
    padding-bottom: 47px;
    @include box-sizing(border-box);
    &--left{
     display: block;
      position: relative;
      float: left;
      width: 296px;
      @include breakpoint(max-width 1024px){
        width: 30%;
      }
      @include breakpoint(max-width 700px){
        width: 50%;
      }
      @include breakpoint(max-width 600px){
        width: 100%;
        margin-bottom: 20px;
      }
      > .footer__links--socials{
        margin-top: 23px;
        margin-bottom: 0;
        > a{
          size: 36px;
        }
      }
    }
    &--title{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 0;
      margin-bottom: 12px;
      padding-left: 40px;
      @include box-sizing(border-box);
      color: #003399;
      font-size: 18px;
      font-weight: 400;
    }
    &--title.address:before{
      display: block;
      position: absolute;
      content: '';
      width: 29px;
      height: 26px;
      top: 0;
      left: 0;
      background-image: url('../img/icons/icon_Home.png');
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    &--title.phone{
      margin-top: 26px;
    }
    &--title.phone:before{
      display: block;
      position: absolute;
      content: '';
      width: 26px;
      height: 25px;
      top: 0;
      left: 0;
      background-image: url('../img/icons/phone_icon.png');
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    &--title.director{
      margin-top: 24px;
      padding-left: 0;
    }
    &--title.requisites{
      margin-top: 28px;
    }
    &--title.requisites:before{
      display: block;
      position: absolute;
      content: '';
      width: 31px;
      height: 23px;
      top: 0;
      left: 0;
      background-image: url('../img/icons/icon-beznal.png');
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    &--title.e-mail:before{
      display: block;
      position: absolute;
      content: '';
      width: 22px;
      height: 27px;
      top: 0;
      left: 0;
      background-image: url('../img/icons/icon_Address Book.png');
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    &--title.recall{
      margin-top: 35px;
    }
    &--title.recall:before{
      display: block;
      position: absolute;
      content: '';
      width: 29px;
      height: 19px;
      top: 0;
      left: 0;
      background-image: url('../img/icons/icon_mes.png');
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    &--descr{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 0;
      margin-bottom: 5px;
      color: #000000;
      font-size: 17px;
      font-weight: 400;
    }
    &--center{
      display: block;
      position: relative;
      float: left;
      width: calc(100% - (296px + 434px));
      padding: 0 63px;
      @include box-sizing(border-box);
      @include breakpoint(max-width 1024px){
        width: 30%;
      }
      @include breakpoint(max-width 700px){
        width: 50%;
      }
      @include breakpoint(max-width 600px){
        width: 100%;
        margin-bottom: 20px;
        padding: 0;
      }
    }
    &--map{
      display: block;
      position: relative;
      float: left;
      width: 434px;
     /* height: 493px;*/
      overflow: hidden;
      @include breakpoint(max-width 1024px){
        width: 30%;
      }
      @include breakpoint(max-width 700px){
        width: 100%;
        height: 300px;
      }
      @include breakpoint(max-width 600px){
        width: 100%;
      }
      > .ymaps {
        display: block;
        position: relative;
        float: left;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
    }
  }
  &__form{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    &--field{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      height: 39px;
      margin-bottom: 14px;
      padding-left: 14px;
      padding-right: 14px;
      @include box-sizing(border-box);
      border: 1px solid rgba(154, 154, 154, 0.53);
      background-color: #ffffff;
      color: #0f0f0f;
      font-size: 16px;
      font-weight: 400;
    }
    &--msg{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      height: 216px;
      resize: none;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 14px;
      padding-right: 14px;
      @include box-sizing(border-box);
      border: 1px solid rgba(154, 154, 154, 0.53);
      background-color: #ffffff;
      color: #0f0f0f;
      font-size: 16px;
      font-weight: 400;
    }
    &--submit{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      height: 39px;
      margin-top: 20px;
      background-color: #2665af;
      border: none;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

$BebasNeueBold: 'Bebas Neue Bold';
@font-face {
  font-family: 'Bebas Neue Bold';
  src: url('../fonts/Bebas/BebasNeueBold.eot') format('embedded-opentype'),
  url('../fonts/Bebas/BebasNeueBold.woff2') format('woff2'),
  url('../fonts/Bebas/BebasNeueBold.woff') format('woff'),
  url('../fonts/Bebas/BebasNeueBold.ttf') format('truetype'),
  url('../fonts/Bebas/BebasNeueBold.svg') format('svg');
  font-weight: normal;
}
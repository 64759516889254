.about{
  display: block;
  position: relative;
  float: left;
  width: 100%;
  &__box{
    @include display-flex(flex);
    @include flex-wrap(wrap);
    @include flex-direction(row);
    position: relative;
    float: left;
    width: 100%;
  }
  .news__title{
    width: 100%;
    text-transform: uppercase;
  }
  .news__box{
    flex: 2 0 0;
    margin-right: 10px;
    @include box-sizing(border-box);
    @include breakpoint(max-width 700px){
      flex: 1 0 0;
    }
  }
  &__poster{
    flex: 1 0 0;
    position: relative;
    float: left;
    margin-bottom: 42px;
    overflow: hidden;
    @include breakpoint(max-width 700px){
      display: none;
    }
    img{
      size: 100%;
    }
  }
}
.gallery {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  &__wrapper {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 47px;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    &--top-links {
      @include display-flex(flex);
      @include flex-wrap(wrap);
      @include flex-direction(row);
      @include justify-content(center);
      position: relative;
      float: left;
      width: 100%;
      margin-top: 31px;
      margin-bottom: 47px;
      @include breakpoint(max-width 1366px) {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      @include breakpoint(max-width 600px) {
        @include flex-direction(column);
      }
      > a {
        @include display-flex(flex);
        @include flex-wrap(wrap);
        @include flex-direction(row);
        @include justify-content(center);
        @include align-items(center);
        position: relative;
        margin: 0 15px;
        color: #000000;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        @include breakpoint(max-width 600px) {
          margin: 15px 0;
        }
        &:hover {
          color: #000000;
          text-decoration: underline;
        }
        > span:first-of-type {
          position: relative;
          width: 28px;
          height: 21px;
          overflow: hidden;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
      > a.active {
        color: #003399;
        &:hover {
          text-decoration: none;
        }
      }
      > a:first-of-type {
        > span:first-of-type {
          background-image: url('../img/icons/icon_photos.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
      }
      > a:first-of-type.active {
        > span:first-of-type {
          background-image: url('../img/icons/icon_photos_on.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
      }
      > a:nth-of-type(2) {
        > span:first-of-type {
          background-image: url('../img/icons/icon_photos.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
      }
      > a:nth-of-type(2).active {
        > span:first-of-type {
          background-image: url('../img/icons/icon_photos_on.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
      }
      > a:nth-of-type(3) {
        > span:first-of-type {
          background-image: url('../img/icons/icon_video.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
      }
      > a:nth-of-type(3).active {
        > span:first-of-type {
          background-image: url('../img/icons/icon_video_on.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }
      }
    }
    &--book-links {
      @include display-flex(flex);
      @include flex-wrap(wrap);
      @include flex-direction(row);
      @include justify-content(center);
      position: relative;
      float: left;
      width: 100%;
      margin-top: 31px;
      margin-bottom: 47px;
      @include breakpoint(max-width 1366px) {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      @include breakpoint(max-width 600px) {
        @include flex-direction(column);
      }
      > a {
        @include display-flex(flex);
        @include flex-wrap(wrap);
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        position: relative;
        margin: 0 15px;
        color: #000000;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        @include breakpoint(max-width 600px) {
          margin: 15px 0;
        }
        &:hover {
          color: #000000;
          text-decoration: underline;
        }
       /* > span:first-of-type {
          position: relative;
          width: 32px;
          height: 31px;
          overflow: hidden;
          margin-bottom: 8px;
        }*/
      }
      > a.active {
        color: #003399;
        &:hover {
          text-decoration: none;
        }
      }
      > a:first-of-type {
        /*> span:first-of-type {
          background-image: url('../img/icons/book-star-icon.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }*/
      }
      > a:first-of-type.active {
        /*> span:first-of-type {
          background-image: url('../img/icons/book-star-hover-icon.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }*/
      }
      > a:nth-of-type(2) {
        /*> span:first-of-type {
          background-image: url('../img/icons/book-star-icon.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }*/
      }
      > a:nth-of-type(2).active {
        /*> span:first-of-type {
          background-image: url('../img/icons/book-star-hover-icon.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }*/
      }
      > a:nth-of-type(3) {
        /*> span:first-of-type {
          background-image: url('../img/icons/book-star-icon.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }*/
      }
      > a:nth-of-type(3).active {
        /*> span:first-of-type {
          background-image: url('../img/icons/book-star-hover-icon.png');
          background-repeat: no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }*/
      }
    }
  }
  &__img-container {
    display: block;
    position: relative;
    width: 100%;
    float: left;
    padding: 0 15px;
    @include box-sizing(border-box);
    &--element {
      display: inline-block;
      position: relative;
      float: left;
      padding: 15px;
      @include box-sizing(border-box);
      img {
        size: 100%;
        object-fit: cover;
      }
    }
    &--element:hover {
      .gallery__img-container--hover {
        opacity: 1;
      }
    }
    &--element:nth-child(1) {
      width: 60%;
      height: 402px;
      @include breakpoint(max-width 1366px) {
        height: 340px;
      }
      @include breakpoint(max-width 700px) {
        height: 320px;
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--element:nth-child(2) {
      width: 20%;
      height: 402px;
      @include breakpoint(max-width 1366px) {
        height: 340px;
      }
      @include breakpoint(max-width 700px) {
        height: 320px;
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--element:nth-child(3) {
      width: 20%;
      height: calc(402px / 2);
      @include breakpoint(max-width 1366px) {
        height: calc(340px / 2);
      }
      @include breakpoint(max-width 700px) {
        height: calc(320px / 2);
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--element:nth-child(4) {
      width: 20%;
      height: calc(402px / 2);
      @include breakpoint(max-width 1366px) {
        height: calc(340px / 2);
      }
      @include breakpoint(max-width 700px) {
        height: calc(320px / 2);
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--element:nth-child(5) {
      width: 60%;
      height: 331px;
      @include breakpoint(max-width 700px) {
        height: 320px;
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--element:nth-child(6) {
      width: 40%;
      height: 331px;
      @include breakpoint(max-width 700px) {
        height: 320px;
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--element:nth-child(7) {
      width: 20%;
      height: calc(402px / 2);
      float: right;
      @include breakpoint(max-width 1366px) {
        height: calc(340px / 2);
      }
      @include breakpoint(max-width 700px) {
        height: calc(320px / 2);
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
        float: left;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--element:nth-child(8) {
      width: 20%;
      height: 403px;
      float: left;
      @include breakpoint(max-width 1366px) {
        height: 340px;
      }
      @include breakpoint(max-width 700px) {
        height: 320px;
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
        height: calc(320px / 2);
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--element:nth-child(9) {
      width: 60%;
      height: 403px;
      float: left;
      @include breakpoint(max-width 1366px) {
        height: 340px;
      }
      @include breakpoint(max-width 700px) {
        height: 320px;
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
        height: calc(320px / 2);
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--element:nth-child(10) {
      width: 20%;
      height: calc(402px / 2);
      float: right;
      @include breakpoint(max-width 1366px) {
        height: calc(340px / 2);
      }
      @include breakpoint(max-width 700px) {
        height: calc(320px / 2);
      }
      @include breakpoint(max-width 660px) {
        width: 30%;
        float: left;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
        height: 320px;
      }
    }
    &--hover {
      display: block;
      position: absolute;
      width: calc(100% - (15px * 2));
      height: calc(100% - (15px * 2));
      opacity: 0;
      @include transition(0.4s);
      top: 15px;
      left: 15px;
      background-color: #cb4741;
      > span {
        display: block;
        position: relative;
        float: left;
        width: 45px;
        height: 45px;
        margin-left: calc(50% - (45px / 2));
        overflow: hidden;
        @include vertical-align;
        img {
          size: 100%;
          object-fit: cover;
        }
      }
    }
    &--event {
      display: block;
      position: relative;
      float: left;
      width: 30%;
      height: 296px;
      overflow: hidden;
      margin: 0 18px 32px;
      > .date {
        display: block;
        position: relative;
        float: left;
        width: 100%;
        color: #a1a1a1;
        font-size: 15px;
        font-weight: 400;
      }
      > .gallery {
        display: block;
        position: relative;
        float: left;
        width: calc(100% - 2px);
        height: calc(100% - (20px + 2px));
        border: 1px solid #e8e8e8;
        overflow: hidden;
        > .gallery__imgs {
          display: block;
          position: relative;
          float: left;
          width: 100%;
          height: 208px;
          background-color: #6b6b6b;
          overflow: hidden;
          &:hover {
            > .counter {
              opacity: 1;
            }
          }
          > .photo {
            display: block;
            position: relative;
            float: left;
            width: 100%;
            height: 208px;
            background-color: #6b6b6b;
            overflow: hidden;
            img {
              size: 100%;
              object-fit: cover;
            }
          }
          > .counter {
            @include display-flex(flex);
            @include flex-wrap(wrap);
            @include justify-content(flex-end);
            @include align-items(center);
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 52px;
            background-color: rgba(177, 177, 177, 0);
            background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
            opacity: 0;
            span {
              color: #c0c0c0;
              font-size: 15px;
              font-weight: 400;
              margin: 0 2px;
              &:last-of-type {
                margin-right: 10px;
              }
            }
          }
        }
        > p {
          display: block;
          position: relative;
          float: left;
          width: 100%;
          margin-top: 0;
          margin-bottom: 0;
          padding: 21px 20px;
          @include box-sizing(border-box);
          text-align: center;
          color: #003399;
          font-size: 16px;
          font-weight: 700;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  &__video-container {
    display: block;
    position: relative;
    width: 100%;
    float: left;
    padding: 0 15px;
    @include box-sizing(border-box);
    &--event {
      display: block;
      position: relative;
      float: left;
      width: 30%;
      height: 230px;
      overflow: hidden;
      margin: 0 18px 32px;
      > .gallery {
        display: block;
        position: relative;
        float: left;
        width: calc(100% - 2px);
        height: calc(100% - (20px + 2px));
        border: 1px solid #e8e8e8;
        overflow: hidden;
        .date {
          display: block;
          position: relative;
          float: left;
          width: 100%;
          color: #a1a1a1;
          font-size: 15px;
          font-weight: 400;
          padding-left: 22px;
          padding-right: 22px;
          padding-bottom: 0;
          @include box-sizing(border-box);
        }
        > .gallery__imgs {
          display: block;
          position: relative;
          float: left;
          width: 100%;
          height: 208px;
          background-color: #6b6b6b;
          overflow: hidden;
          &:hover {
            > .counter {
              opacity: 1;
            }
          }
          > .photo {
            display: block;
            position: relative;
            float: left;
            width: 100%;
            height: 208px;
            background-color: #6b6b6b;
            overflow: hidden;
            img {
              size: 100%;
              object-fit: cover;
            }
          }
          > .counter {
            @include display-flex(flex);
            @include flex-wrap(wrap);
            @include justify-content(flex-end);
            @include align-items(center);
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 52px;
            background-color: rgba(177, 177, 177, 0);
            background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
            opacity: 0;
            span {
              color: #c0c0c0;
              font-size: 15px;
              font-weight: 400;
              margin: 0 2px;
              &:last-of-type {
                margin-right: 10px;
              }
            }
          }

        }
        > p {
          display: block;
          position: relative;
          float: left;
          width: 100%;
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 11px;
          padding-left: 22px;
          padding-right: 22px;
          padding-bottom: 0;
          @include box-sizing(border-box);
          text-align: left;
          color: #003399;
          font-size: 16px;
          font-weight: 700;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  &__booking-container {
    display: block;
    position: relative;
    width: 100%;
    float: left;
    padding: 0 15px;
    @include box-sizing(border-box);
    &--element {
      display: block;
      position: relative;
      float: left;
      width: calc(25% - 2px);
      height: 169px;
      background-color: #6b6b6b;
      margin: 0 1px;
      @include breakpoint(max-width 600px) {
        width: calc(100% - 2px);
      }
      > .photo {
        display: block;
        position: relative;
        float: left;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          size: 100%;
          object-fit: cover;
        }
      }
      > .name {
        display: block;
        display: -webkit-box;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 10px 0;
        @include box-sizing(border-box);
        background-color: rgba(177, 177, 177, 0);
        background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
        color: #ffffff;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        line-height: 1.2;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    /*&--event {
      display: block;
      position: relative;
      float: left;
      width: 30%;
      height: 296px;
      overflow: hidden;
      margin: 0 18px 32px;

      > .photo {
        display: block;
        position: relative;
        float: left;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          size: 100%;
          object-fit: cover;
        }
      }
    }*/
  }
  &__subtitle {
    display: block;
    position: relative;
    float: left;
    width: calc(100% - (18px * 2));
    border-bottom: 1px solid #c7c7c7;
    color: #444444;
    font-size: 22px;
    font-weight: 400;
    padding-bottom: 9px;
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 30px;
  }
}
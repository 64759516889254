.header__navigation{
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.header__trigger{
  display: none;
  position: relative;
  float: left;
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 10px;
  @include breakpoint(max-width 700px){
    display: block;
  }
  > span{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: 2px;
    background-color: #cb4741;
    margin-bottom: 8px;
  }
}
.header__nav{
  @include display-flex(flex);
  @include flex-wrap(wrap);
  @include flex-direction(row);
  @include justify-content(space-between);
  position: relative;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  @include breakpoint(max-width 700px){
    display: none;
  }
  li{
    position: relative;
    float: left;
    @include breakpoint(max-width 700px){
      width: 100%;
    }
    a{
      display: block;
      position: relative;
      float: left;
      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 25px;
      padding-right: 25px;
      @include box-sizing(border-box);
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      text-transform: uppercase;
      &:hover{
        color: #003399;
      }
      @include breakpoint(max-width 700px){
        width: 100%;
        text-align: center;
      }
    }
  }
  li:nth-of-type(5){
    @include breakpoint(max-width 700px){
      display: none;
    }
    a{
      padding: 0;
      z-index: 2;
      margin-bottom: -138px;
    }
  }
}
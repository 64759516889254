.main-content {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  background-color: #f7f7f7;
  padding-top: 141px;
  @include box-sizing(border-box);
  @include breakpoint(max-width 700px){
    padding-top: 0;
  }
  &__wrapper{
    @include display-flex(flex);
    @include flex-wrap(wrap);
    position: relative;
    float: left;
    width: 100%;
  }
  &__sidebar {
    display: block;
    position: relative;
    float: left;
    width: 307px;
    margin-top: 67px;
    margin-bottom: 35px;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    @include breakpoint(max-width 700px){
      width: 100%;
    }
    &--title {
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 17px;
      padding-bottom: 13px;
      /*padding-left: 7px;
      padding-right: 7px;*/
      @include box-sizing(border-box);
      /*border-bottom: 1px solid #d7d7d7;*/
      color: #cb4741;
      font-size: 25px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      &:before {
        display: block;
        position: absolute;
        content: '';
        top: 21px;
        left: 56px;
        width: 27px;
        height: 26px;
        background-image: url('../img/icons/icon_star_news.png');
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
      }
      &:after {
        display: block;
        position: absolute;
        content: '';
        left: 7px;
        margin-top: 13px;
        width: calc(100% - (7px * 2));
        height: 1px;
        background-color: #d7d7d7;
      }
    }
    &--news {
      display: block;
      position: relative;
      float: left;
      width: 100%;
      padding-left: 23px;
      padding-right: 23px;
      @include box-sizing(border-box);
      /*border-bottom: 1px solid #d7d7d7;*/
      &:after {
        display: block;
        position: absolute;
        content: '';
        left: 23px;
        bottom: 5px;
        width: calc(100% - (23px * 2));
        height: 1px;
        background-color: #d7d7d7;
      }
      > span {
        display: block;
        position: relative;
        float: left;
        width: 100%;
        margin-top: 15px;
        padding-left: 40px;
        padding-right: 40px;
        @include box-sizing(border-box);
        color: #6f6f6f;
        font-size: 18px;
        font-weight: 400;
      }
      > p {
        display: block;
        display: -webkit-box;
        position: relative;
        float: left;
        width: 100%;
        height: calc(18px * 1.2 * 5);
        padding-left: 40px;
        padding-right: 40px;
        @include box-sizing(border-box);
        margin-top: 10px;
        margin-bottom: 10px;
        color: #000000;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > a {
        display: block;
        position: relative;
        float: left;
        color: #cb4741;
        font-size: 18px;
        font-weight: 400;
        text-decoration: underline;
        padding-left: 40px;
        padding-right: 40px;
        @include box-sizing(border-box);
        margin-bottom: 25px;
        &:hover {
          color: #cb4741;
          text-decoration: none;
        }
      }
    }
  }
  &__more-news {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 13px;
    margin-bottom: 13px;
    color: #cb4741;
    font-size: 16px;
    font-weight: 400;
    > span {
      display: block;
      position: relative;
      float: left;
      &:first-of-type{
        width: 16px;
        height: 16px;
        margin-left: 94px;
        margin-right: 7px;
        overflow: hidden;
        img{
          size: 100%;
        }
      }
      &:last-of-type {
        text-decoration: underline;
        margin-top: -3px;
      }
    }
    &:hover {
      color: #cb4741;
      span:last-of-type {
        text-decoration: none;
      }
    }
  }
  &__main{
    display: block;
    position: relative;
    float: left;
    margin-left: 18px;
    margin-top: 67px;
    margin-bottom: 35px;
    width: calc(100% - (307px + 18px + 4px));
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    @include breakpoint(max-width 700px){
      width: 100%;
      margin-left: 0;
    }
    &--title{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 17px;
      padding-bottom: 13px;
      padding-left: 57px;
      @include box-sizing(border-box);
      color: #003399;
      font-size: 25px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
      &:before {
        display: block;
        position: absolute;
        content: '';
        top: 21px;
        left: 24px;
        width: 27px;
        height: 26px;
        background-image: url('../img/icons/icon_star.png');
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
      }
      &:after {
        display: block;
        position: absolute;
        content: '';
        left: 7px;
        margin-top: 13px;
        width: calc(100% - (7px * 2));
        height: 1px;
        background-color: #d7d7d7;
      }
    }
    &--more-poster{
      display: block;
      position: absolute;
      right: 18px;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 13px;
      color: #003399;
      font-size: 16px;
      font-weight: 400;
      > span {
        display: block;
        position: relative;
        float: left;
        &:first-of-type{
          width: 16px;
          height: 16px;
          margin-left: 94px;
          margin-right: 7px;
          overflow: hidden;
          img{
            size: 100%;
          }
        }
        &:last-of-type {
          text-decoration: underline;
          margin-top: -3px;
        }
      }
      &:hover {
        color: #003399;
        span:last-of-type {
          text-decoration: none;
        }
      }
    }
  }
  &__posters{
    @include display-flex(flex);
    @include flex-wrap(wrap);
    @include flex-direction(row);
    @include justify-content(space-between);
    position: relative;
    float: left;
    width: 100%;
    padding-left: 34px;
    padding-right: 34px;
    @include box-sizing(border-box);
    @include breakpoint(max-width 700px){
      padding: 0;
    }
    &--poster{
      display: block;
      position: relative;
      float: left;
      width: calc(50% - 15px);
      height: 265px;
      margin-top: 53px;
      @include breakpoint(max-width 700px){
        width: 100%;
        margin-top: 30px;
      }
      > h3{
        display: block;
        display: -webkit-box;
        position: relative;
        float: left;
        width: calc(100% - 180px);
        min-height: 19px;
        max-height: calc(20px * 1.2 * 2);
        margin-top: -4px;
        margin-bottom: 0;
        padding-left: 19px;
        @include box-sizing(border-box);
        color: #0669b2;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoint(max-width 700px){
          min-height: 19px;
          max-height: calc(19px * 1.2 * 2);
          font-size: 19px;
        }
      }
      > .date{
        display: block;
        position: relative;
        float: left;
        width: calc(100% - 180px);
        margin-top: 0;
        margin-bottom: 5px;
        padding-left: 19px;
        @include box-sizing(border-box);
        color: #cb4741;
        font-size: 20px;
        font-weight: 700;
        @include breakpoint(max-width 700px){
          font-size: 19px;
        }
      }
      > .descr{
        display: block;
        display: -webkit-box;
        position: relative;
        float: left;
        width: calc(100% - 180px);
        min-height: 16px;
        max-height: calc(16px * 1.2 * 3);
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 19px;
        @include box-sizing(border-box);
        color: #cb4741;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoint(max-width 700px){
          font-size: 15px;
          min-height: 16px;
          max-height: calc(15px * 1.2 * 3);
        }
      }
      > .where{
        display: block;
        position: relative;
        float: left;
        width: calc(100% - 180px);
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 19px;
        @include box-sizing(border-box);
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        @include breakpoint(max-width 700px){
          font-size: 15px;
        }
        > span{
          color: #cd4943;
          font-weight: 700;
        }
      }
      > a {
        display: block;
        position: absolute;
        right: 0;
        bottom: 36px;
        width: calc(100% - 180px);
        color: #cb4741;
        font-size: 18px;
        font-weight: 400;
        text-decoration: underline;
        padding-left: 19px;
        @include box-sizing(border-box);
        margin-bottom: 0;
        &:hover {
          color: #cb4741;
          text-decoration: none;
        }
      }
      > .buy{
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 143px;
        height: 29px;
        cursor: pointer;
        background-color: #cb4741;
        border: none;
        > span:first-of-type{
          display: inline-block;
          float: left;
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.5;
          text-transform: uppercase;
        }
        > span:last-of-type{
          display: inline-block;
          width: 23px;
          height: 23px;
          float: right;
          background-image: url('../img/icons/icon-tickets.png');
          background-repeat: no-repeat;
          -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
        }
      }
    }
    &--img{
      display: block;
      position: relative;
      float: left;
      width: 180px;
      height: 265px;
      overflow: hidden;
      img{
        size: 100%;
      }
    }
  }
}
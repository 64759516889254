.top-banner{
  display: block;
  position: relative;
  float: left;
  width: 100%;
  background-color: #fff;
  &__element{
    display: block;
    position: relative;
    float: left;
    width: 152px;
    height: 113px;
    margin-top: 10px;
    margin-bottom: 78px;
    margin-right: 63px;
    @include breakpoint(max-width 700px){
      width: 100%;
      height: 113px;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 0;
    }
    > span{
      display: block;
      position: relative;
      float: left;
      width: 32px;
      height: 31px;
      margin-left: calc(50% - (32px/2));
      overflow: hidden;
      img{
        size: 100%;
        object-fit: contain;
      }
    }
    > p{
      display: block;
      position: relative;
      float: left;
      width: 100%;
      margin-top: 14px;
      margin-bottom: 0;
      color: #ffffff;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }
  }
  &__element:nth-last-of-type(2){
    float: right;
    margin-right: 0;
    margin-left: 63px;
  }
  &__element:last-of-type{
    float: right;
    margin-right: 0;
    margin-left: 63px;
  }
  &__slider{
    display: block;
    position: relative;
    float: left;
    width: calc(100% - 4px);
    height: calc(505px - 8px);
    margin-top: -38px;
    margin-bottom: -141px;
    border: 2px solid #fff;
    z-index: 2;
    /*@include breakpoint(max-width 1366px){
      height: calc(440px - 8px);
    }*/
    @include breakpoint(max-width 700px){
      display: none!important;
    }
    &:after{
      display: block;
      position: absolute;
      content: '';
      margin-top: -21px;
      left: 0;
      width: 100%;
      height: 49px;
      background-image: url('../img/content/index/shadow-slider.png');
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      z-index: 1;
    }
    &--slide{
      display: block;
      position: relative;
      float: left;
      width:100%;
      height: calc(505px - 8px);
      overflow: hidden;
      /*@include breakpoint(max-width 1366px){
        height: calc(390px - 8px);
      }*/
      img{
        size: 100%;
        object-fit: cover;
      }
    }
    .owl-nav{
      display: block;
      position: absolute;
      width: 100%;
      height: 40px;
      top: calc(50% - (40px/2));
      left: 0;
      .owl-prev{
        display: block;
        position: relative;
        float: left;
        width: 24px;
        height: 40px;
        margin-left: 9px;
        color: transparent;
        background-image: url('../img/icons/left_a.png');
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
      }
      .owl-next{
        display: block;
        position: relative;
        float: right;
        width: 24px;
        height: 40px;
        margin-right: 9px;
        color: transparent;
        background-image: url('../img/icons/right_a.png');
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
      }
    }
    .owl-dots{
      @include display-flex(flex);
      @include flex-wrap(wrap);
      @include flex-direction(row);
      @include justify-content(center);
      position: relative;
      float: left;
      width: 100%;
      margin-top: 12px;
      margin-bottom: 41px;
      z-index: 2;
      .owl-dot{
        display: block;
        position: relative;
        float: left;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: #000000;
        margin: 0 4px;
      }
      .owl-dot.active{
        background-color: #0669b2;
      }
    }
  }
  &__title{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    margin-top: 178px;
    margin-bottom: 37px;
    color: #ffffff;
    font-size: 41px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    @include breakpoint(max-width 1366px){
      margin-top: 138px;
      margin-bottom: 17px;
    }
  }
}
.top-banner.main-banner{
  background-image: url('../img/content/index/top-banner-main.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.top-banner.news-banner{
 /* height: 246px;*/
  background-image: url('../img/content/news/top-banner-news.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.top-banner.single-news-banner{
  height: 246px;
  background-image: url('../img/content/news/top-banner-single-news.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.top-banner.poster-banner{
 /* height: 246px;*/
  background-image: url('../img/content/posters/top-banner-poster.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.top-banner.about-banner{
  height: 246px;
  background-image: url('../img/content/about/about-top-banner.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.top-banner.gallery-banner{
  /* height: 246px;*/
  background-image: url('../img/content/gallery/top-banner-gallery.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}